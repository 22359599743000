import React from 'react';
import Layout from '../layout/layout/Layout';
import { Helmet } from 'react-helmet';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { GuideToCali } from '../components/GuideToCali/GuideToCali';

import gsap from 'gsap';

import 'normalize.css';

gsap.registerPlugin(ScrollTrigger);

export default ({}) => {
    return (
        <>
            <img
                style={{ border: 'none', display: 'none' }}
                src="https://r.turn.com/r/beacon?b2=ENFfbvx4UQlcNXurrUdMXjtsleZbK-0NxGKagzYLnLmLKcaUyZfoNJuH2MK_jNSYlhPJUwYor-xwawHhMeJcRQ&cid="
            />
            <Helmet title="Get Real - Got Milk.">
                <link href="https://use.typekit.net/edu7mvc.css" rel="stylesheet" />
            </Helmet>
            <Layout pageClassName="home-page">
                <GuideToCali />
            </Layout>
        </>
    );
};
