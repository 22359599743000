import React, { useEffect } from 'react';
import { ButtonVariantEnum } from '../../../utils/enums/button';
import Button from '../../button/Button';
import { Text } from '../../Text/Text';

import './ExploreBox.scss'

type ExploreBoxProps = {
    headline: string;
    description: string;
    customClass?: string;
    buttonLabel: string;
    onMainAction: () => void;
};

export const ExploreBox = ({ headline, description, buttonLabel, customClass = '', onMainAction }: ExploreBoxProps) => {
    return (
        <div className={`explore-box ${customClass}`}>
            <Text size="title2" tag="p" textAlign="center" color="white" customClass="explore-box__title">
                {headline}
            </Text>
            <Text size="body" tag="p" textAlign="center" customClass="explore-box__description">
                {description}
            </Text>
            <Button
                label={buttonLabel}
                type={ButtonVariantEnum.QUATERNARY}
                onClick={onMainAction}
                className="explore-box__button"
            />
        </div>
    );
};
