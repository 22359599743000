import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../store/AppContextProvider';
import { GenericMedia } from '../../utils/types/mediaPost';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

import './MediaCard.scss';

const videoPlayerPropsOptions: any = {
    autoplay: false,
    controls: true,
    muted: true,
    fill: true,
    techOrder: ['html5'],
    userActions: {
        doubleClick: false,
        click: false,
    },
    controlBar: {
        fullscreenToggle: false,
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
        playToggle: false,
    },
    bigPlayButton: true,
    playsinline: true,
};

type MediaCardProps = {
    autoplay?: boolean;
    source: GenericMedia;
    poster: GenericMedia;
    isActive: boolean;
    id: string;
};

export const MediaCard = ({ source, isActive, id, poster, autoplay = true }: MediaCardProps) => {
    const { currentLandingVideo, setCurrentLandingVideo } = useContext(AppContext);
    const [videoPlayer, setVideoPlayer] = useState<any | undefined>(undefined);
    const [isReady, setIsReady] = useState(false);

    const handlePlayerReady = (player: any) => {
        setVideoPlayer(player);

        const restartVideoUnmuted = (currentPlayer: any) => {
            currentPlayer.currentTime(0);
            currentPlayer.muted(false);
            currentPlayer.play();
            setCurrentLandingVideo(id);
            currentPlayer.setAttribute('isPaused', false);
            currentPlayer.setAttribute('isMuted', false);
            currentPlayer.removeClass('vjs-muted-video');
        };

        player.bigPlayButton.on('click', function () {
            if (player.getAttribute('isMuted') === 'true') {
                restartVideoUnmuted(player);
                return;
            }

            if (player.getAttribute('isPaused') === 'true') {
                player.play();
                setCurrentLandingVideo(id);
                player.setAttribute('isPaused', false);
            } else {
                player.pause();
                player.setAttribute('isPaused', true);
            }
        });

        player.on('click', function (evt: any) {
            if (evt.target.tagName === 'VIDEO') {
                restartVideoUnmuted(player);
            }
        });

        player.on('touchend', function (evt: any) {
            if (player.getAttribute('isMuted') === 'true') {
                restartVideoUnmuted(player);
                return;
            }

            if (player.getAttribute('isPaused') === 'true') {
                player.play();
                setCurrentLandingVideo(id);
                player.setAttribute('isPaused', false);
                player.addClass('vjs-force-show-pause-button');
                setTimeout(() => {
                    if (player?.hasClass('vjs-force-show-pause-button')) {
                        player?.removeClass('vjs-force-show-pause-button');
                    }
                }, 1500);
            } else {
                player.pause();
                player.setAttribute('isPaused', true);
                if (player?.hasClass('vjs-force-show-pause-button')) {
                    player?.removeClass('vjs-force-show-pause-button');
                }
            }
        });
    };

    useEffect(() => {
        if (videoPlayer && isActive) {
            if (autoplay) {
                videoPlayer.ready(function () {
                    videoPlayer.play().then(() => {
                        setIsReady(true);
                        videoPlayer.addClass('vjs-muted-video');
                        setTimeout(() => {
                            videoPlayer.addClass('vjs-do-not-show-big-play-button');
                        }, 2000);
                    });
                });
            }

            videoPlayer.setAttribute('isPaused', false);
            videoPlayer.setAttribute('isMuted', true);
        }
    }, [videoPlayer, isActive]);

    useEffect(() => {
        if (isReady) {
            if (id !== currentLandingVideo) {
                videoPlayer.ready(function () {
                    videoPlayer?.pause();
                    videoPlayer.setAttribute('isPaused', true);
                });
            }
        }
    }, [isReady, currentLandingVideo]);

    return (
        <section className={`media-card ${isActive ? 'media-card--active' : ''}`}>
            <div className="media-card__wrapper">
                <VideoPlayer
                    {...{
                        options: {
                            ...videoPlayerPropsOptions,
                            sources: [
                                {
                                    src: source.url,
                                    type: 'video/mp4',
                                },
                            ],
                            poster: poster.url,
                            preload: 'auto',
                        },
                    }}
                    onReady={handlePlayerReady}
                />
            </div>
        </section>
    );
};
