import React from 'react';
import { Hashtag } from '../../utils/types/city';
import AnimatedCounter from '../AnimatedCounter/AnimatedCounter';

import './HashtagTable.scss';

type HashtagTableProp = {
    hashtags: Hashtag[];
    isActive?: boolean;
};

export const HashtagTable = ({ hashtags, isActive }: HashtagTableProp) => {
    return (
        <div className="hashtags-table ">
            <div className="hashtags-table__header">
                <p className="hashtags-table__column-label">HASHTAG</p>
                <p className="hashtags-table__column-label">MENTIONS</p>
            </div>
            <div className="hashtags-table__body">
                {hashtags?.map((hashtag, index) => {
                    return (
                        <div key={`hashtag-${index}`} className="hashtags-table__hashtag-item hashtag-item">
                            <span className="hashtag-item__title">#{hashtag.name}</span>
                            <span className="hashtag-item__count">
                                {isActive && <AnimatedCounter counter={hashtag.count}></AnimatedCounter>}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
