import React from 'react';
import MapBg from '../../assets/images/bg-map.inline.svg';

import './Background.scss';

type BackgroundProps = {
    isLoading: boolean;
};

export const Background = ({ isLoading }: BackgroundProps) => {
    return (
        <section className={`background ${isLoading ? 'background--loading' : ''}`} aria-hidden="true">
            <div className="background__wrapper">
                <div
                    className={`background__california-line ${
                        !isLoading ? 'background__california-line--visible' : null
                    }`}
                >
                    <MapBg />
                </div>
                <div className="background__street-outlines"></div>
            </div>
        </section>
    );
};
