import React, { FC, useEffect } from 'react';

import './Loader.scss';

export const Loader = () => {
    useEffect(() => {
        const displayHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--display-height', `${window.innerHeight}px`);
        };
        window.addEventListener('resize', displayHeight);
        displayHeight();

        return () => {
            window.removeEventListener('resize', displayHeight);
        };
    }, []);

    return (
        <section aria-hidden="true" className="loader">
            <div className="loader__wrapper">
                <img src="./images/loader.gif" className="loader__image" />
            </div>
        </section>
    );
};
