import React, { ReactNode, useState } from 'react';

import './Text.scss';

type FontSize = 'body' | 'subtitle' | 'title1' | 'title2' | 'title3' | 'hint';

type TextAlign = 'auto' | 'left' | 'right' | 'center' | 'justify';

type TagElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

type TextProps = {
    size: FontSize;
    color?: string;
    textAlign?: TextAlign;
    tag: TagElement;
    children?: ReactNode;
    customClass?: string;
    useDangerouslySetInnerHTML?: boolean;
    ref?: any;
};

export const Text = ({
    size,
    color,
    textAlign,
    tag = 'p',
    children,
    customClass,
    useDangerouslySetInnerHTML = false,
    ref,
}: TextProps) => {
    const [TextTag] = useState(`${tag}` as any);

    if (useDangerouslySetInnerHTML) {
        return (
            <TextTag
                ref={ref}
                className={`text text__size--${size} ${textAlign ? `text__align--${textAlign}` : ''} ${
                    customClass ?? ''
                }`}
                style={color ? { color: color } : null}
                dangerouslySetInnerHTML={{ __html: children }}
            />
        );
    }

    return (
        <TextTag
            ref={ref}
            className={`text text__size--${size} ${textAlign ? `text__align--${textAlign}` : ''} ${customClass}`}
            style={color ? { color: color } : null}
        >
            {children}
        </TextTag>
    );
};
