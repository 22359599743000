import React, { useContext, useEffect, useRef, useState } from 'react';
import { MediaCard } from '../MediaCard/MediaCard';
import { Text } from '../Text/Text';
import { AppContext } from '../../store/AppContextProvider';
import { GuideToCaliHero } from '../../types/GuideToCaliHero';
import Typewriter, { TypewriterClass } from 'typewriter-effect';

import bodymovin, { AnimationItem } from 'lottie-web';

import './Hero.scss';

type HeroProps = {
    isActive: boolean;
    data: GuideToCaliHero;
};

const HERO_MEDIA_ID = 'hero-media';

const defaultMediaPoster = {
    url: '/images/guide-to-cali/media-placeholder.png',
};

export const Hero = ({ isActive = false, data }: HeroProps) => {
    const { setCurrentLandingVideo } = useContext(AppContext);
    const [showAnimatedMedia, setShowAnimatedMedia] = useState(false);
    const [lottieAnimation, setLottieAnimation] = useState<AnimationItem>();

    const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
        })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    const heroMedia = useRef<HTMLDivElement>(null);
    const heroHeader = useRef<HTMLDivElement>(null);
    const heroSubheading = useRef<HTMLDivElement>(null);
    const heroDescription = useRef<HTMLDivElement>(null);
    const heroScrollToExplore = useRef<HTMLDivElement>(null);
    const heroAnimatedMedia = useRef<HTMLDivElement>(null);

    const typeWriterAnimationDelay = window.innerWidth < 768 ? 75 : 50;

    useEffect(() => {
        const anim = bodymovin.loadAnimation({
            container: document.getElementsByClassName('animated-media__lottie')[0],
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/lotties/california-animation.json',
        });

        setLottieAnimation(anim);
    }, []);

    useEffect(() => {
        setCurrentLandingVideo(HERO_MEDIA_ID);

        if (isActive) {
            heroMedia.current?.classList.add('animation-play');
            heroHeader.current?.classList.add('animation-play');
            heroSubheading.current?.classList.add('animation-play');
            heroDescription.current?.classList.add('animation-play');
            heroScrollToExplore.current?.classList.add('animation-play');
            heroAnimatedMedia.current?.classList.add('animation-play');
        }
    }, [isActive]);

    const handleTypewritterEnd = () => {
        setTimeout(() => {
            setShowAnimatedMedia(true);
        }, 250);

        if (lottieAnimation && !isSafari) {
            lottieAnimation.play();
        }
    };

    const handleTypewritterInit = (typewriter: TypewriterClass) => {
        typewriter.pauseFor(350).callFunction(handleTypewritterEnd).typeString(data.heading).start();
    };

    return (
        <section className="hero">
            <div className="hero__wrapper">
                <div ref={heroMedia} className="hero__media hero__animation--step-1 animated fadeInUp">
                    <MediaCard
                        id={HERO_MEDIA_ID}
                        isActive={isActive}
                        source={data.mediaSource}
                        poster={data.mediaPoster || defaultMediaPoster}
                    ></MediaCard>
                </div>
                <div className="hero__content">
                    <div ref={heroHeader} className="hero__header hero__animation--step-1 animated fadeInUp">
                        <h1 className="sr-only">The Realest Guide to California</h1>
                        {isActive && (
                            <Text tag="h1" size="title1" customClass="hero__headline" aria-hidden="true">
                                <Typewriter
                                    options={{
                                        wrapperClassName: 'hero__headline-typewriter',
                                        delay: typeWriterAnimationDelay,
                                    }}
                                    onInit={(typewriter: TypewriterClass) => handleTypewritterInit(typewriter)}
                                />
                            </Text>
                        )}
                        <div ref={heroAnimatedMedia} className="hero__animated-media animated-media" aria-hidden="true">
                            {showAnimatedMedia ? (
                                <img
                                    src="/lotties/california.gif"
                                    alt="California"
                                    className={`animated-media__image ${
                                        isSafari ? 'animated-media__image--force-show' : ''
                                    }`}
                                />
                            ) : null}

                            {!isSafari && <div className="animated-media__lottie"></div>}
                        </div>
                    </div>
                    <div ref={heroSubheading} className="hero__subheading hero__animation--step-2 animated fadeInUp">
                        <Text tag="h2" size="title2" customClass="hero__copy" useDangerouslySetInnerHTML>
                            {data.subHeading}
                        </Text>
                        <Text tag="p" size="hint" customClass="hero__hint" useDangerouslySetInnerHTML>
                            {data.subHeadingSmall}
                        </Text>
                    </div>
                    <div ref={heroDescription} className="hero__description hero__animation--step-3 animated fadeInUp">
                        <Text tag="p" size="body">
                            {data.body}
                        </Text>
                    </div>
                    <div
                        ref={heroScrollToExplore}
                        className="hero__scroll-to-explore hero__animation--step-4 animated fadeInUp"
                    >
                        <Text tag="p" size="hint" color="#FCB900" customClass="hero__scroll-to-explore-text">
                            {data.scrollLabel}
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
};
