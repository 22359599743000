import React, { useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { ButtonVariantEnum } from '../../utils/enums/button';
import { Copyright } from '../Copyright/Copyright';
import { ExploreBox } from './ExploreBox/ExploreBox';
import Button from '../button/Button';

import gsap from 'gsap';

import './Footer.scss';

type BottomBox = {
    body: string;
    ctaLabel: string;
};

type FooterProps = {
    bottomBox: BottomBox;
    handleShareGuideClick: () => void;
    isActive: boolean;
};

export const Footer = ({ bottomBox, handleShareGuideClick, isActive }: FooterProps) => {
    const footerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isActive) return;
        
        gsap.timeline({
            scrollTrigger: {
                trigger: `.footer`,
                start: `top 75%`,
                scrub: 1,
                onEnter: () => {
                    footerRef.current?.classList.add('animation-play');
                },
            },
        });
    }, [isActive]);

    const handleExploreMap = () => {
        navigate('/map');
    };

    return (
        <footer ref={footerRef} className="footer animated fadeIn">
            <ExploreBox
                headline="STILL FEELING LIKE A TOURIST?"
                description="Click here to experience the interactive map that we created to discover which California city is as real as real milk."
                buttonLabel="EXPLORE THE MAP"
                onMainAction={handleExploreMap}
            />
            <Copyright isStatic={true} customClass="footer__show-desktop" />
            <ExploreBox
                headline="SHARE THE GUIDE"
                description="Want to make your friends feel like a tourist in their own city?"
                buttonLabel="SHARE THE GUIDE"
                customClass="footer__show-mobile"
                onMainAction={handleShareGuideClick}
            />
            {bottomBox ? (
                <div className="footer__bottom-box bottom-box footer__show-desktop">
                    <p className="bottom-box__text">{bottomBox.body}</p>
                    <Button
                        label={bottomBox.ctaLabel}
                        type={ButtonVariantEnum.QUATERNARY}
                        onClick={handleShareGuideClick}
                        className="bottom-box__button"
                    />
                </div>
            ) : null}
        </footer>
    );
};
