import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

import './Sticker.scss';

type StickerProps = {
    image?: string;
    text?: string;
    customClass?: string;
    id: string;
};

export const Sticker = ({ image, text, customClass = '', id }: StickerProps) => {
    const selector = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selector) {
            gsap.timeline({
                scrollTrigger: {
                    trigger: selector.current,
                    start: `top 75%`,
                    scrub: 1,
                    onEnter: () => {
                        selector.current?.classList.add('animation-play');
                    },
                },
            });
        }
    }, [selector]);

    return (
        <div ref={selector} className={`sticker sticker--id-${id} animated fadeInUp ${customClass}`}>
            <div className="sticker__wrapper">
                <div className="sticker__media">
                    {image && <img className="sticker__image" src={image} alt="stickerImage" />}
                </div>
                <div className="sticker__content">
                    {text && <p className="sticker__text" dangerouslySetInnerHTML={{ __html: text }}></p>}
                </div>
            </div>
        </div>
    );
};
