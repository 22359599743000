import React, { useEffect, useRef, useState } from 'react';
import { City } from '../../utils/types/city';
import { Text } from '../Text/Text';
import { HashtagTable } from '../HashtagTable/HashtagTable';
import AnimatedCounter from '../AnimatedCounter/AnimatedCounter';

import gsap from 'gsap';

import './CityHeader.scss';

type CityHeaderProps = {
    city: City;
};

export const CityHeader = ({ city: { name, rank, hashtags, id } }: CityHeaderProps) => {
    const [isActive, setIsActive] = useState(false);
    const headerWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: `.city-header--id-${id}`,
                start: `center 75%`,
                scrub: 1,
                onEnter: () => {
                    setIsActive(true);
                    headerWrapperRef.current?.classList.add('animation-play');
                },
            },
        });
    }, []);

    return (
        <div className={`city-header city-header--id-${id}`}>
            <div ref={headerWrapperRef} className="city-header__wrapper animated fadeInUp">
                <Text size="title2" tag="p" textAlign="center" customClass="city-header__headline">
                    {`#${rank} ${name}`}
                </Text>
                {hashtags ? (
                    <>
                        <div className="city-header__hashtags-table">
                            <HashtagTable hashtags={hashtags} isActive={isActive}></HashtagTable>
                        </div>
                        <div className="city-header__hashtags-mentions hashtags-mentions">
                            {hashtags.map((hashtag, index) => {
                                return (
                                    <Text
                                        key={`hashtag-item-${index}`}
                                        size="subtitle"
                                        tag="p"
                                        customClass="hashtags-mentions__item"
                                    >
                                        {`#${hashtag.name}: `}
                                        {isActive && <AnimatedCounter counter={hashtag.count}></AnimatedCounter>}
                                    </Text>
                                );
                            })}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};
