import React, { useEffect, useState } from 'react';
import { City } from '../../utils/types/city';
import { CityMenuDropdown } from './CityMenuDropdown';
import { CityMenuList } from './CityMenuList';

import './CityMenu.scss';

type CityMenuProps = {
    cities: City[];
    selectedCity?: City;
    onCitySelected: (city: City) => void;
};

export const CityMenu = (props: CityMenuProps) => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setWidth]);

    const isMobile = () => width < 768;

    return (
        <div className={`city-menu city-menu--${isMobile() ? 'dropdown' : 'list'}  animated fadeIn`}>
            {isMobile() ? <CityMenuDropdown {...props} /> : <CityMenuList {...props} />}
        </div>
    );
};

export type { CityMenuProps };
