import React from 'react';
import { CityMenuProps } from './CityMenu';

export const CityMenuList = ({ cities, selectedCity, onCitySelected }: CityMenuProps) => {
    return (
        <div className="city-menu-list">
            {cities.map((city, index) => {
                return (
                    <div
                        className={`city-menu-list__city-item city-item ${
                            city.id === selectedCity?.id ? 'city-item--active' : ''
                        }`}
                        onClick={() => onCitySelected(city)}
                        key={`city-menu-item-${index}`}
                    >
                        <div className="city-item__bullet">
                            <div className="city-item__bullet-circle"></div>
                        </div>
                        <p className="city-item__name">{city.name}</p>
                    </div>
                );
            })}
        </div>
    );
};
