import React, { useEffect } from 'react';
import { CityHeader } from '../CityHeader/CityHeader';
import { City } from '../../utils/types/city';
import { CityPost } from '../CityPost/CityPost';
import './CitySection.scss';

import gsap from 'gsap';

const isiOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

type Direction = 'up' | 'down';

type CitySectionProps = {
    cityIndex: number;
    city: City;
    onLeaveSection: (id: string, direction: Direction) => void;
};

export const CitySection = ({ cityIndex, city, onLeaveSection }: CitySectionProps) => {
    useEffect(() => {
        const citySectionTl = gsap.timeline({
            scrollTrigger: {
                trigger: `.city-section--id-${city.id}`,
                start: `top center`,
                end: 'bottom center',
                scrub: 1,
                onLeave: () => {
                    onLeaveSection(city.id, 'down');
                },
                onLeaveBack: () => {
                    onLeaveSection(city.id, 'up');
                },
            },
        });
    }, []);

    return (
        <div className={`city-section city-section--id-${city.id}`}>
            <CityHeader city={city} />
            {city.mediaPosts.map((mp, index) => {
                return (
                    <CityPost
                        mediaPost={mp}
                        align={index % 2 === 0 ? 'Right' : 'Left'}
                        id={`${city.name.replace(/\s+/g, '-').toLowerCase()}-${index}`}
                        key={`city-post-${index}-${city.id}`}
                        sticker={
                            city.hasSticker && index === 0
                                ? { source: city.stickerImage, text: city.stickerText }
                                : undefined
                        }
                        autoplay={cityIndex > 8 && isiOS() ? false : true}
                    />
                );
            })}
        </div>
    );
};
