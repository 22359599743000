import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../store/AppContextProvider';
import { City } from '../../utils/types/city';
import { Background } from '../background/Background';
import { CityMenu } from '../CityMenu/CityMenu';
import { CitySection } from '../CitySection/CitySection';
import { Footer } from '../Footer/Footer';
import { Hero } from '../Hero/Hero';
import { Loader } from '../loader/Loader';
import { Modal } from '../Modal/Modal';

import SocialMediaBox from '../heatmap/socialMediaBox/SocialMediaBox';
import RoadBgDesktop from '../../assets/images/road-bg-desktop.inline.svg';
import RoadBgMobile from '../../assets/images/road-bg-mobile.inline.svg';

import gsap from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

export const GuideToCali = () => {
    const { pageContent } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCity, setSelectedCity] = useState<City | undefined>();
    const [isGuideModalOpen, setIsGuideModalOpen] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(0);

    const loaderTl = useRef({} as GSAPTimeline);
    const roadTl = useRef({} as GSAPTimeline);

    const roadBgMobile = useRef<HTMLDivElement>(null);
    const roadBgDesktop = useRef<HTMLDivElement>(null);
    const landingCitiesContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setWidth]);

    useEffect(() => {
        document.querySelector('html')?.classList.add('disable-scroll');
        // Loader timeline
        loaderTl.current = gsap.timeline({
            onComplete: () => {
                document.querySelector('html')?.classList.remove('disable-scroll');
                document.querySelector('.hero')?.classList.add('hero--visible');
                document.querySelector('.navbar')?.classList.add('navbar--visible');
                roadBgMobile.current?.classList.add('animation-play');
                roadBgDesktop.current?.classList.add('animation-play');
                setIsLoading(false);
            },
        });
        loaderTl.current.set('.loader', { autoAlpha: 1 });
        loaderTl.current.set('.loader__image', { autoAlpha: 1 });
        loaderTl.current.to('.loader', { autoAlpha: 0, pointerEvents: 'none', duration: 1, delay: 5.1 }, 'start');
        loaderTl.current.to('.loader__image', { autoAlpha: 0, duration: 1, delay: 5 }, 'start');
    }, []);

    useEffect(() => {
        if (pageContent?.guideToCali?.cities) {
            setSelectedCity(pageContent?.guideToCali?.cities[0]);
        }
    }, [pageContent]);

    useEffect(() => {
        if (isLoading) return;

        if (Object.keys(roadTl.current).length !== 0) return;
        roadTl.current = gsap.timeline({ defaults: { ease: 'none', drawSVG: '0% 0%' } });

        const isMobile = () => width < 768;
        const wrapperClass = isMobile()
            ? '.landing-cities-content__road-bg--mobile .road-bg__animated-svg'
            : '.landing-cities-content__road-bg--desktop .road-bg__animated-svg';
        const svg = document.querySelector(wrapperClass)?.children[0];

        let offsetHeight: number = 0;
        svg?.childNodes?.forEach((item) => {
            if (!item.id) return;
            const pathHeight = item.getBBox().height;
            const pathId = item.id;
            const start = `${offsetHeight} 50%`;
            const end = `${offsetHeight + pathHeight} 50%`;

            offsetHeight = offsetHeight + pathHeight;

            roadTl.current.fromTo(`#${pathId}`, {}, { stroke: '#FCB900' }, 0).fromTo(
                `#${pathId}`,
                { drawSVG: '0%' },
                {
                    drawSVG: '-100%',
                    stroke: '#FCB900',
                    scrollTrigger: {
                        trigger: wrapperClass,
                        scrub: true,
                        start: start,
                        end: end,
                    },
                },
            );
        });
    }, [isLoading]);

    const handleOnCitySelected = (city: City) => {
        setSelectedCity(city);
        const citySectionContainerClass = '.city-section.city-section--id-';
        document.querySelector(`${citySectionContainerClass}${city.id}`)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

        landingCitiesContentRef.current?.classList.add('is-scrolling');

        var timer: any = null;
        let handleScroll = () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                landingCitiesContentRef.current?.classList.remove('is-scrolling');
                let cityId = landingCitiesContentRef.current?.getAttribute('citi-id');
                if (cityId && pageContent?.guideToCali?.cities) {
                    let cityIndex: number = pageContent?.guideToCali?.cities.findIndex((c) => c.id === cityId);
                    let newCity = pageContent?.guideToCali?.cities[cityIndex];
                    setSelectedCity(newCity);
                }
                landingCitiesContentRef.current?.removeAttribute('citi-id');
                window.removeEventListener('scroll', handleScroll, true);
            }, 50);
        };
        window.addEventListener('scroll', handleScroll, true);
    };

    const updateSelectedCity = (currentCityId: string, direction: 'up' | 'down') => {
        if (!pageContent?.guideToCali?.cities) {
            return;
        }

        let cityIndex: number = pageContent?.guideToCali?.cities.findIndex((c) => c.id === currentCityId);
        let newSelectedCityIndex: number =
            direction === 'up'
                ? cityIndex !== 0
                    ? cityIndex - 1
                    : 0
                : cityIndex !== pageContent?.guideToCali?.cities.length - 1
                ? cityIndex + 1
                : pageContent?.guideToCali?.cities.length - 1;
        let newCity = pageContent?.guideToCali?.cities[newSelectedCityIndex];

        if (landingCitiesContentRef.current?.classList.contains('is-scrolling')) {
            landingCitiesContentRef.current?.setAttribute('citi-id', newCity.id);
        } else {
            setSelectedCity(newCity);
        }
    };

    return (
        <>
            {isGuideModalOpen && (
                <Modal
                    handleClose={() => {
                        setIsGuideModalOpen(false);
                    }}
                    customClass="guide-modal"
                >
                    <SocialMediaBox
                        title={'Want to make your friends feel like a tourist in their own city?'}
                        twitter={
                            'https://twitter.com/intent/tweet?text=Think%20you%20can%20handle%20the%20realness?%20Check%20out%20@gotmilk%E2%80%99s%20Realest%20Guide%20to%20California%20for%20yourself.%20#getrealmilk%20#realestguidetocalifornia%20#gotmilk'
                        }
                        facebook="https://www.facebook.com/gotmilk/"
                        instagram="https://www.instagram.com/gotmilk/"
                        tiktok="https://www.tiktok.com/@gotmilk"
                        copylink="https://get-real-california.gotmilk.com/"
                    ></SocialMediaBox>
                </Modal>
            )}
            <Background isLoading={isLoading} />
            <Loader />
            {pageContent?.guideToCali.hero && <Hero isActive={!isLoading} data={pageContent?.guideToCali.hero} />}
            <div ref={landingCitiesContentRef} className="landing-cities-content">
                <div className="landing-cities-content__road-bg-container">
                    <div className="road-bg road-bg--mobile landing-cities-content__road-bg--mobile">
                        <div ref={roadBgMobile} className="road-bg__animated-svg animated fadeIn">
                            <RoadBgMobile />
                        </div>
                    </div>
                    <div className="road-bg road-bg--desktop landing-cities-content__road-bg--desktop ">
                        <div ref={roadBgDesktop} className="road-bg__animated-svg animated fadeIn">
                            <RoadBgDesktop />
                        </div>
                    </div>
                </div>
                {pageContent?.guideToCali?.cities && (
                    <CityMenu
                        cities={pageContent?.guideToCali?.cities}
                        selectedCity={selectedCity}
                        onCitySelected={handleOnCitySelected}
                    />
                )}
                {pageContent?.guideToCali?.cities.map((city, index) => (
                    <CitySection
                        cityIndex={index}
                        city={city}
                        onLeaveSection={(id, direction) => {
                            updateSelectedCity(id, direction);
                        }}
                        key={`city-section-${index}`}
                    />
                ))}
            </div>
            <Footer
                bottomBox={{
                    body: pageContent?.guideToCali.footer.body ?? '',
                    ctaLabel: pageContent?.guideToCali.footer.ctaLabel ?? '',
                }}
                handleShareGuideClick={() => {
                    setIsGuideModalOpen(true);
                }}
                isActive={!isLoading}
            />
        </>
    );
};
