import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../store/AppContextProvider';
import { MediaCard } from '../MediaCard/MediaCard';
import { Text } from '../Text/Text';
import { MediaPost } from '../../utils/types/mediaPost';
import { Sticker } from '../Sticker/Sticker';
import MapMarker from '../../assets/icons/MapMarker.inline.svg';

import gsap from 'gsap';

import './CityPost.scss';

export type StickerPost = {
    source?: string;
    text?: string;
};

type CityPostProps = {
    id: string;
    mediaPost: MediaPost;
    align: 'Right' | 'Left';
    sticker?: StickerPost | undefined;
    autoplay?: boolean;
};

const defaultMediaPoster = {
    url: '/images/guide-to-cali/media-placeholder.png',
};

export const CityPost = ({
    id,
    mediaPost: { title, description, mediaSource, mediaPoster },
    align = 'Right',
    sticker,
    autoplay = true,
}: CityPostProps) => {
    const [isActive, setIsActive] = useState(false);
    const { setCurrentLandingVideo } = useContext(AppContext);
    const cityPostMedia = useRef<HTMLDivElement>(null);
    const cityPostContent = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: `.city-post--id-${id}`,
                start: `top 75%`,
                scrub: 1,
                onEnter: () => {
                    setIsActive(true);
                    document.querySelector(`.city-menu`)?.classList.add('animation-play');
                    cityPostContent.current?.classList.add('animation-play');
                    cityPostMedia.current?.classList.add('animation-play');
                },
            },
        });
    }, []);

    useEffect(() => {
        if (isActive) {
            setCurrentLandingVideo(id);
        }
    }, [isActive]);

    return (
        <div
            className={`city-post city-post--align-${align === 'Right' ? 'right' : 'left'} city-post--id-${id} ${
                sticker ? 'city-post--has-sticker' : ''
            }`}
        >
            <div ref={cityPostMedia} className="city-post__media animated fadeInUp">
                <MediaCard
                    isActive={isActive}
                    source={mediaSource}
                    poster={mediaPoster ?? defaultMediaPoster}
                    id={id}
                    autoplay={autoplay}
                />
            </div>
            <div ref={cityPostContent} className="city-post__content animated fadeInUp">
                <Text
                    size="title3"
                    tag="p"
                    textAlign="center"
                    customClass="city-post__title"
                    useDangerouslySetInnerHTML
                >
                    {title}
                </Text>
                <Text size="body" tag="p" textAlign="center" customClass="city-post__body" useDangerouslySetInnerHTML>
                    {description}
                </Text>
                <div className="city-post__marker-icon">
                    <MapMarker />
                </div>
            </div>
            {sticker ? (
                <Sticker
                    image={sticker?.source}
                    text={sticker?.text}
                    customClass="city-post__sticker"
                    id={`sticker-${id}`}
                />
            ) : null}
        </div>
    );
};
