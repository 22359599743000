import React, { useState } from 'react';
import { CityMenuProps } from './CityMenu';
import Chevron from '../../assets/icons/chevron-down.inline.svg';

export const CityMenuDropdown = ({ cities, selectedCity, onCitySelected }: CityMenuProps) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    return (
        <div className="city-menu-dropdown">
            <div
                className="city-menu-dropdown__selected-city selected-city"
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <p className="selected-city__text">
                    JUMP TO:
                    <span className="selected-city__name">{selectedCity?.name}</span>
                </p>
                <div
                    className={`selected-city__icon
                            ${isCollapsed ? 'selected-city__icon--collapsed' : ''}`}
                >
                    <Chevron />
                </div>
            </div>
            <div
                className={`city-menu-dropdown__cities-list cities-list
                    ${isCollapsed ? 'cities-list--collapsed' : ''}`}
            >
                {cities.map((city) => {
                    if (city.id !== selectedCity?.id) {
                        return (
                            <div
                                key={city.id}
                                className="cities-list__city-item"
                                onClick={() => {
                                    onCitySelected(city);
                                    setIsCollapsed(true);
                                }}
                            >
                                <p className="cities-list__city-name">{city.name}</p>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
};
