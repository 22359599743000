import React, { useEffect, useRef } from 'react';

import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import './VideoPlayer.scss';

export type VideoPlayerProps = {
    options: any;
    onReady?: any;
};

export const VideoPlayer = ({ options, onReady }: VideoPlayerProps) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = (playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);
            }));

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <video
                ref={videoRef}
                className="video-player video-js vjs-big-play-centered vjs-fade-out vjs-show-big-play-button-on-pause"
                style={{ objectFit: options.fill ? 'cover' : 'initial' }}
            />
        </div>
    );
};

export default VideoPlayer;
